import React, { useState } from "react";
import { useTranslation } from "react-i18next";


const BannerTwo = () => {
  const {t} =useTranslation();  

  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* ================== BannerTwo Start ==================*/}
      <div
        className="banner-area bg-relative banner-area-2 bg-cover"
        style={{ backgroundImage: 'url("./assets/img/bg/8.png")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="banner-inner pe-xl-5">
                <h2
                  className="title"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                >
                  {t("bannerTwo_title")}  <br/><span>{t("bannerTwo_title_span")}</span>
                </h2>
                <p
                  className="content"
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                >
                {t("header_subtitle")}
                </p>
                <div className="app-links">
                <a
                    href="assets/img/stpazar.apk"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="assets/img/banner-2/DirectDownload.png"
                      alt="Direct Download"
                      style={{
                        height: "50px",
                        paddingRight: "5px",
                        borderRadius: 13,
                        paddingTop: 5,
                      }}
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/tr/app/stp-pazar/id6499310961"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="assets/img/banner-2/appstore.png"
                      alt="Download on the App Store"
                      style={{
                        height: "50px",
                        paddingRight: "5px",
                        paddingTop: 5,
                      }}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.stpazar.stbsoft"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="assets/img/banner-2/google.png"
                      alt="Get it on Google Play"
                      style={{
                        height: "50px",
                        paddingRight: "5px",
                        paddingTop: 5,
                      }}
                    />
                  </a>
                  <a
                    href="https://stp-pazar.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="assets/img/banner-2/web.png"
                      alt="STP Pazar Web"
                      style={{
                        height: "50px",
                        paddingRight: "5px",
                        paddingTop: 5,
                      }}
                    />
                  </a>
                </div>
                
              </div>
            </div>

            <div
              className="col-lg-6 col-md-9 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="banner-thumb-2 mt-4 mt-lg-0">
                <img
                  className="banner-animate-img banner-animate-img-4"
                  src="assets/img/banner-2/3.png"
                  alt="img"
                />
                <div className="main-img-wrap p-5">
                  <img
                    className="banner-animate-img banner-animate-img-1 left_image_bounce"
                    src="assets/img/banner-2/4.png"
                    alt="img"
                  />
                  <img
                    className="banner-animate-img banner-animate-img-2 left_image_bounce"
                    src="assets/img/banner-2/5.png"
                    alt="img"
                  />
                  {/* <img
                    className="banner-animate-img banner-animate-img-3 top_image_bounce"
                    src="assets/img/banner-2/2.png"
                    alt="img"
                  /> */}
                  <img
                    className="main-img"
                    src="assets/img/banner-2/1.png"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== BannerTwo End ==================*/}
    </>
  );
};

export default BannerTwo;
