import React from "react";
// import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const TeamAreaOne = () => {
  const { t } = useTranslation();
  return (
    <>
      {/*====================== team area start ======================*/}
      <div className='team-area bg-relative pd-top-90 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>{t("our_partners")}</h6>
            <h2 className='title'>
            {t("our_partners_title")}
            </h2>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/1.png' alt='img' />
                  {/* <ul className='team-social-inner'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                  </ul> */}
                </div>
                <div className='details'>
                  <h5>
                  <a href="https://a16.com.tr/" >A16</a>
                  </h5>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                  {/* <ul className='team-social-inner'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                  </ul> */}
                </div>
                <div className='details'>
                  <h5>
                  <a href="https://spdelivery.com.tr/" >SPEED DELIVERY</a>
                  </h5>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/3.png' alt='img' />
                  {/* <ul className='team-social-inner'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                  </ul> */}
                </div>
                <div className='details'>
                  <h5>
                  <a href="https://stllojistik.com.tr/">STL LOJISTIK</a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================== team area end ======================*/}
    </>
  );
};

export default TeamAreaOne;
