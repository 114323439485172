import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import HomeTwo from "./pages/HomeTwo";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "react-scroll-to-top";
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import en from "./Locales/en.json";
import tr from "./Locales/tr.json";
import ar from "./Locales/ar.json";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";

const defaultLanguage = localStorage.getItem('i18nextLng') || 'en';
i18next.init({
  interpolation: { escapeValue: false },  
  lng: defaultLanguage,  
  resources: {
    en: { translation: en },
    ar: { translation: ar },
    tr: { translation: tr },
  },
});

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  }, [i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<HomeTwo />} />
          <Route exact path='/about' element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        </Routes>
        <ScrollToTop smooth color='#246BFD' />
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
