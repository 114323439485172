import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavBar = () => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [dir, setDir] = useState(i18n.dir());

  useEffect(() => {
    setDir(i18n.dir());
  }, [i18n.language]);

  const menuActive = () => {
    setActive(!active);
  };

  // Control sidebar navigation
  useEffect(() => {
    let items = document.querySelectorAll(".menu-item-has-children > a");
    items.forEach((item) => {
      item.onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    });
  }, []);

  return (
    <>
      {/* navbar start */}
      <nav className="navbar navbar-area navbar-area-2 navbar-expand-lg">
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={
                active
                  ? `menu toggle-btn d-block d-lg-none open ${dir === 'rtl' ? 'rtl-menu' : ''}`
                  : `menu toggle-btn d-block d-lg-none ${dir === 'rtl' ? 'rtl-menu' : ''}`
              }
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo">
            <Link to="/">
              <img src="assets/img/banner-2/2525.png" alt="img" />
            </Link>
          </div>

          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id="itech_main_menu"
          >
            <ul className="navbar-nav menu-open text-lg-end">
              <li>
                <Link to="/">{t("home")}</Link>
              </li>
              <li>
                <Link to="/about">{t("about")}</Link>
              </li>
              <li className="menu-item-has-children">
                <a href="#">{t("languages")}</a>
                <ul className="sub-menu">
                  <li>
                    <a href="#" onClick={() => { i18n.changeLanguage('en'); }}>{t("english")}</a>
                  </li>
                  <li>
                    <a href="#" onClick={() => { i18n.changeLanguage('ar'); }}>{t("arabic")}</a>
                  </li>
                  <li>
                    <a href="#" onClick={() => { i18n.changeLanguage('tr'); }}>{t("turkish")}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="nav-right-part nav-right-part-desktop align-self-center">
            <Link className="btn btn-border-base" to="/about">
              {t("Discover_more")}
            </Link>
          </div>
        </div>
      </nav>
      {/* navbar end */}
    </>
  );
};

export default NavBar;
