import React from "react";
import {
  FaChevronRight,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FooterTwo = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className='footer-area footer-area-2 bg-gray mt-0 pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_about'>
                <div >
                  <img src="assets/img/banner-2/2525.png" alt='img' />
                </div>
                <div className='details'>
                  <p> {t("footer_information_logo")} </p>

                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 ps-xl-5'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>{t("footer_our_services")}</h4>
                <div className="app-links">
                  <a
                    href="https://apps.apple.com/tr/app/stp-pazar/id6499310961"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="assets/img/banner-2/appstore.png"
                      alt="Download on the App Store"
                      style={{
                        height: "50px",
                        paddingRight: "5px",
                        paddingTop: 5,
                      }}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.stpazar.stbsoft"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="assets/img/banner-2/google.png"
                      alt="Get it on Google Play"
                      style={{
                        height: "50px",
                        paddingRight: "5px",
                        paddingTop: 5,
                      }}
                    />
                  </a>
                  <a
                    href="https://drive.google.com/uc?export=download&id=1GFCdlfW6khuNk88E1N1w4JDD_t-A-c6L"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="assets/img/banner-2/DirectDownload.png"
                      alt="Direct Download"
                      style={{
                        height: "50px",
                        paddingRight: "5px",
                        borderRadius: 10,
                        paddingTop: 5,
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 ps-xl-5'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>{t("pages_footer")}</h4>
                <ul>
                  <li>
                    <Link to='/'>
                      <FaChevronRight />  {t("home")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/about'>
                      <FaChevronRight /> {t("about")}
                    </Link>
                  </li>
                      
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget-recent-post'>
                <h4 className='widget-title'>{t("home")}</h4>
                <div className='widget widget_contact'>
                  <ul className='details'>
                    <li>
                      <FaMapMarkedAlt />
                      <a href="https://maps.app.goo.gl/HAXr3XHxRa12Vb5h9">  Mehmet abdi bulut mah. fatma şahin cad. üzümcüoğlu oğlu Sitesi A-block No: 1A MERKAZ/KİLİS Tahtalı Köyü</a>
                    </li>
                    <li className='mt-3'>
                      <FaPhoneAlt /> <a href="tel:08508888783">08508888783</a>
                    </li>
                    <li className='mt-2'>
                      <FaEnvelope /> <a href="mailto:info@stp-pazar.com">info@stp-pazar.com</a>
                    </li>
                  </ul>
                  {/* <ul className='social-media mt-4'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaYoutube />
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p>{t("all_rights_reserved")}</p>
              </div>
              <div className='col-md-6 text-lg-end'>
                <Link to='/terms-and-conditions'>{t("terms_and_conditions")}</Link>
                <a></a>
                <Link to='/privacy-policy'>{t("privacy_policy")}</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default FooterTwo;
