import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ArabicTranslation from "./Locales/ar.json";
import EnglishTranslation from "./Locales/en.json";
import TurkishTranslation from "./Locales/tr.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: "ar", // تعيين اللغة الافتراضية إلى العربية
    fallbackLng: "en", // تعيين اللغة البديلة إلى العربية
    interpolation: {
      escapeValue: false, // ليس مطلوبًا لـ React لأنه يقوم بالهروب افتراضيًا
    },
    react: {
      useSuspense: false,
    },
    resources: {
      tr: {
        translation: TurkishTranslation,
      },
      en: {
        translation: EnglishTranslation,
      },
      ar: {
        translation: ArabicTranslation,
      },
    },
  });

export default i18n;
