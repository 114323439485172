import React from "react";
import AboutAreaOne from "../components/AboutAreaOne";
import FaqAreaOne from "../components/FaqAreaOne";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";
import TeamAreaOne from "../components/TeamAreaOne";
const About = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarTwo />

      {/* About Area One */}
      <AboutAreaOne />

      {/* FAQ Area One */}
      <FaqAreaOne />

      {/* Team Area One */}
      <TeamAreaOne />

      {/* Footer One */}
      <FooterTwo />
    </>
  );
};

export default About;
